<template>
  <div>
    <div class="container-bg-white">

      <invoices-filters :showDateSelectors=false
                        :filter-options="filterOptions"
                        :filters="filters"
                        :filterForImport=true
                        :statusTransPatch="'flexben.voucher_stocks.import.statuses.'">
        <template v-slot:summary_top>
          <div class="row px-3 pt-3">
            <h4 class="col-12 m-0">
              {{ $t('flexben.voucher_stocks.titles.import_summary') }}
            </h4>
          </div>
          <div v-if="generalErrorMessage!==null"
               class="row px-3 pt-3">
            <div class="col-12 m-0 text-danger text-bold">
              {{generalErrorMessage }}
            </div>
          </div>
        </template>
        <template v-slot:summary_bottom>
          <hr class="my-0">
          <div class="row px-3 pb-3">
            <div class="col-12 col-md-6 col-lg-3 pt-3">{{ $t('flexben.import.summary.total_items') }} {{ summary.total
              }}
            </div>
            <div class="col-12 col-md-6 col-lg-3 pt-3 text-success">{{ $t('flexben.import.summary.total_valid') }} {{
              summary.ok_count }}
            </div>
            <div class="col-12 col-md-6 col-lg-3 pt-3 text-danger">{{ $t('flexben.import.summary.total_invalid') }} {{
              summary.error_count }}
            </div>
            <div class="col-12 col-md-6 col-lg-3 pt-3 text-warning">{{
              $t('flexben.voucher_stocks.import.summary.total_update') }}: {{
              summary.update_count }}
            </div>
            <div class="col-12 col-md-6 col-lg-3 pt-3 text-info">{{ $t('flexben.import.summary.total_selected') }} {{
              checkedItems.length }}
            </div>
            <div class="col-12 col-md-6 col-lg-3 pt-3 text-info offset-lg-6 text-lg-right">
              <button class="btn btn-danger" :disabled=" checkedItems.length===0"
                      @click="deleteItems()">{{ $t('flexben.general.btn.delete') }}
              </button>
            </div>
          </div>
        </template>
      </invoices-filters>

    </div>
    <div class="container-bg-white p-3 mt-3">
      <div class="row">
        <h2 class="col-12 m-0">{{ $t('flexben.voucher_stocks.titles.import_items') }}</h2>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-responsive hover-table">
            <table class="table table-striped">
              <thead>
              <tr>
                <td></td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.invoice_number") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.code") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.voucher_value") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.up_purchase_date") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.partner") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.partner_cui") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.invoice_value_without_discount") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.discount_value") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.invoice_value_with_discount") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.up_ro_invoice_value_with_discount") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.discount_percentage") }}</td>
                <td>{{ $t("flexben.voucher_stocks.invoicesFields.comments") }}</td>
                <td>{{ $t("flexben.voucher_stocks.fields.status") }}</td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              <template v-if="items.data.length>0">

                <tr v-for="(item,index) of items.data" :key="item.id"
                    class="trhover parent">
                  <td>
                    <input type="checkbox"
                           class="form-check"
                           :checked="checkedItems.includes(item.id)"
                           @change="toggleCheckedItem(item.id)"/>
                  </td>
                  <td>{{ item.purchase_invoice_number || '-' }}</td>
                  <td>{{ item.code || '-' }}</td>
                  <td>{{ item.voucher_value || '-' }}</td>
                  <td>{{ moment(item.up_purchase_date, 'DD.MM.YYYY') || '-' }}</td>
                  <td>{{ item.partner_name || '-' }}</td>
                  <td>{{ item.partner_cui || '-' }}</td>
                  <td>{{ item.invoice_value_without_discount || '-' }}</td>
                  <td>{{ item.discount_value || '-' }}</td>
                  <td>{{ item.invoice_value_with_discount || '-'}}</td>
                  <td>{{ item.up_ro_invoice_value_with_discount || '-'}}</td>
                  <td>{{ item.discount_percentage || '-' }}</td>
                  <td>{{ item.comments || '-' }}</td>
                  <td>
                     <span class="flx-type-badge"
                           :class="{
                      'bg-success':item.status==1,
                      'bg-danger':item.status==2,
                    }">{{ $t('flexben.voucher_stocks.import.statuses.'+item.status) }}</span>
                    <span v-if="item.status==2 && item.invalid_reasons !== null" :id="'invalid_tooltip_'+index"
                          class="ml-1 item-error-reason">!</span>
                    <b-tooltip v-if="item.status==2 && item.invalid_reasons !== null"
                               :target="'invalid_tooltip_'+index" variant="flexben"
                               placement="topleft">
                      <div v-html="getInvalidReasons(item.invalid_reasons)"></div>
                    </b-tooltip>
                  </td>
                  <td>
                    <button class="btn action-button" @click="showDetails(index)">
                      {{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="trhover gri-cell">
                  <td colspan="100" class="text-center">{{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12 p-0">
          <listPagination
            :activePage="items.current_page"
            :currentPage="items.current_page"
            :totalItems="items.total"
            :startItem="items.from"
            :endItem="items.to"
            :itemsPerPage="items.perPage"
            @pageChanged="pageChanged"
            @changePerPage="changePerPage"></listPagination>
        </div>
      </div>
    </div>
    <ImportInvoicesDetailsModal ref="invoice_details_modal"
                                   :availableOptions="filterOptions"
                                   action="import"
                                   @closeAction="clearDetails"
                                   @confirmAction="updateVoucher"
                                   @deleteAction="deleteInvoiceItem">

    </ImportInvoicesDetailsModal>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import InvoicesFilters from "./InvoicesFilters";
import {BTooltip} from "bootstrap-vue";
import ImportInvoicesDetailsModal from "../modals/ImportInvoicesDetailsModal";


export default {
  name: "ImportVoucherInvoicesStep2",
  components: {
    'invoices-filters': InvoicesFilters,
    'b-tooltip': BTooltip,
    'ImportInvoicesDetailsModal': ImportInvoicesDetailsModal
  },
  props: {
    importId: {
      type: Number,
      default:
        null
    }
  },
  data() {
    return {
      httpService: httpFlexbenService.benefitVoucherInvoicesStocks,
      import: null,
      checkedItems: [],
      filterOptions: {
        partners: [],
        statuses: [],
      },
      filters: {
        partner: null,
        status: 5,
        search: null,
        perPage: 20,
        page: 1
      },
      items: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      summary: {
        total: 0,
        ok_count: 0,
        error_count: 0,
        update_count: 0
      },
      invoiceDetails: {
        id: null,
      },
      generalErrorMessage: null
    };
  },
  watch: {
    filters: {
      handler: function (val) {
        this._getImportItems();
      }
      ,
      deep: true
    }
  },
  created() {
    this.setGeneralErrorMessage();
    this.getImportDetails();
  },
  methods: {
    getImportDetails() {
      this.toggleLoading(true);

      this.httpService.getImportData(this.importId, {
        initial: (this.import === null) ? 1 : 0
      })
        .then((response) => {
          if (this.import === null) {
            this.import = response.data.import;
            this.filterOptions = response.data.filer_options;
          }
          this.items = response.data.items;
          this.summary = response.data.summary;
          this.toggleLoading(false);
          this.setInvalidCount(this.summary.error_count);
        })
        .catch((exception) => {
          this.toggleLoading(true, true);
        });
    },
    _getImportItems() {
      this.toggleLoading(true);
      console.log("filters",this.filters);
      this.httpService.getImportItems(this.importId, this.filters)
        .then((response) => {
          this.items = response.data.items;
          this.toggleLoading(false);
        })
        .catch((exception) => {
          this.toggleLoading(true, true);
        });
    }
    ,
    toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError);
    },
    changePerPage(perPage) {
      this.filters.page = 1;
      this.filters.perPage = perPage;
      this._getImportItems();
    },
    pageChanged(pageNb) {
      this.filters.page = pageNb;
      this._getImportItems();
    },
    getInvalidReasons(invalidReasons) {
      let errorList = [];
      let indexKey, errorField;
      for (errorField in invalidReasons) {
        if (typeof invalidReasons[errorField] === 'string') {
          errorList.push(this.$t('flexben.formFieldError', {
            field: this.$t('flexben.voucher_stocks.invoicesFields.' + errorField),
            reason: this.$t('flexben.formErrorReasons.voucher_invoices_errors.' + invalidReasons[errorField])
          }))
        } else {
          for (indexKey in invalidReasons[errorField]) {
            errorList.push(this.$t('flexben.formFieldError', {
              field: this.$t('flexben.voucher_stocks.invoicesFields.' + errorField),
              reason: this.$t('flexben.formErrorReasons.voucher_invoices_errors.' + invalidReasons[errorField][indexKey])
            }))
          }
        }
      }
      return errorList.join('<br>');
    },
    showDetails(index) {
      this.clearDetails();
      this.toggleLoading(true);
      this.httpService.getImportItemDetails(this.import.id, this.items.data[index].id)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.invoiceDetails = data.data;
          this.$refs['invoice_details_modal'].openModal(this.invoiceDetails);
          this.toggleLoading(false);
        })
        .catch((error) => {
          this.toggleLoading(true, true);
        });
    },
    updateVoucher(data) {
      console.log(data)
      this.toggleLoading(true);
      this.httpService.updateImportItem(this.import.id, data.id, data)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.invoiceDetails = data.data;
          this.toggleLoading(false);
          if (this.invoiceDetails.status === 2) {
            this.$refs['invoice_details_modal'].openModal(this.invoiceDetails);
          }
          this.getImportDetails();
        })
        .catch((error) => {
          this.toggleLoading(true, true);
        });
    },
    deleteItems() {
      this.toggleLoading(true);
      this.httpService.deleteImportItems(this.import.id, {item_ids: this.checkedItems})
        .then((response) => {
          this.checkedItems = [];
          this.getImportDetails();
        })
        .catch((error) => {
          this.toggleLoading(true, true);
        });
    },
    deleteInvoiceItem(id) {
      this.checkedItems = [id];
      this.deleteItems();
    },
    clearDetails() {
      this.invoiceDetails = {
        id: null
      };
    },
    toggleCheckedItem(itemId) {
      if (this.checkedItems.includes(itemId)) {
        this.checkedItems.splice(this.checkedItems.indexOf(itemId), 1);
      } else {
        this.checkedItems.push(itemId)
      }
    },
    setInvalidCount(invalidCount = 0) {
      this.$emit('setInvalidCount', invalidCount);
    },
    setGeneralErrorMessage(message = null) {
      this.generalErrorMessage = message;
    }
  }
}
</script>
