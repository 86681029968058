<template>
  <div>
    <b-modal data-backdrop="static"
             data-keyboard="false"
             :clickToClose="false"
             no-close-on-esc
             no-close-on-backdrop
             id="modal-1"
             ref="details_modal"
             size="xl"
             :style="'z-index: 2500'"
             headerClass="border-0 text-center"
             bodyClass="modal-body no-border background-white"
             footerClass="modal-footer border-top-0"
             centered>

      <template v-slot:modal-header>
          <h2 class="mb-0 mx-auto text-center">
            {{ $t("flexben.voucher_stocks.invoicesModalFields.modalTitle", { partner: 'Ikea', invoice_number: invoiceDetails.purchase_invoice_number }) }}
          <span class="flx-type-badge"
                :class="{
                      'bg-success':invoiceDetails.status==1,
                      'bg-danger':invoiceDetails.status==2
                    }">{{ $t('flexben.voucher_stocks.import.statuses.'+invoiceDetails.status) }}</span>
          </h2>
      </template>

      <template v-slot:default="{ hide }">
        <div class="row text-danger" v-if="invoiceDetails.invalid_reasons !== null">
          <div class="col-12 mb-1"
               v-html="getInvalidReasons(invoiceDetails.invalid_reasons)">
          </div>
        </div>
        <div class="row">
          <div class="container-bg-white">
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="purchase_invoice_number" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.invoice_number") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('purchase_invoice_number')}"
                     v-model.trim="invoiceDetails.purchase_invoice_number">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="code" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.code") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('code')}"
                     v-model.trim="invoiceDetails.code">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="voucher_value" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.voucher_value") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('voucher_value')}"
                     v-model.trim="invoiceDetails.voucher_value">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="start_date" class="col-form-label pt-0">
                {{ $t("flexben.voucher_stocks.invoicesFields.up_purchase_date") }}
              </label>
              <div class="d-inline-flex w-100">
                <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
                <datepicker :format="dateFormat" :inputClass="'form-control-d m-0 w-100 background-white'"
                            v-model="invoiceDetails.up_purchase_date"
                            :class="{'w-100':true, 'border-danger':checkInErrorBag('end_date')}"
                            id="end_date"></datepicker>
                <img class="cursor-pointer ml-2" alt="x"
                     src="@/assets/images/flexben/table-remove-button.svg"
                     @click="invoiceDetails.up_purchase_date=null"/>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="partner_name" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.partner") }}</label>
              <input type="text" class="form-control w-100 m-0" disabled
                     :class="{'border-danger':checkInErrorBag('partner_name')}"
                     v-model.trim="invoiceDetails.partner_name">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="partner_name" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.partner_cui") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('partner_cui')}"
                     v-model.trim="invoiceDetails.partner_cui">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="invoice_value_without_discount" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.invoice_value_without_discount") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('invoice_value_without_discount')}"
                     v-model.trim="invoiceDetails.invoice_value_without_discount">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="discount_value" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.discount_value") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('discount_value')}"
                     v-model.trim="invoiceDetails.discount_value">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="invoice_value_with_discount" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.invoice_value_with_discount") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('invoice_value_with_discount')}"
                     v-model.trim="invoiceDetails.invoice_value_with_discount">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="discount_percentage" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.discount_percentage") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('discount_percentage')}"
                     v-model.trim="invoiceDetails.discount_percentage">
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group mb-0">
              <label for="up_ro_invoice_value_with_discount" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.up_ro_invoice_value_with_discount") }}</label>
              <input type="text" class="form-control w-100 m-0"
                     :class="{'border-danger':checkInErrorBag('vat_percentage')}"
                     v-model.trim="invoiceDetails.up_ro_invoice_value_with_discount">
            </div>
            <div class="col-12 form-group mb-0">
              <label for="comments" class="col-form-label">{{ $t("flexben.voucher_stocks.invoicesFields.comments") }}</label>
              <textarea class="form-control w-100 m-0"
                        :class="{'border-danger': checkInErrorBag('comments')}"
                        v-model.trim="invoiceDetails.comments"
                        rows="4"></textarea>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="w-100 d-flex">
          <button class="btn text-themed mt-2" @click="closeModal()">{{ $t('flexben.general.btn.close') }}
          </button>
          <button
                  class="btn btn-themed-red ml-auto px-4" @click="deleteAction()">{{
            $t('flexben.general.btn.delete') }}
          </button>
          <button
                  class="btn btn-themed ml-2 px-4"
                  @click="confirmAction()">{{
            $t('flexben.general.btn.save') }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  name: 'ImportInvoicesDetailsModal',
  components: {
    datepicker: Datepicker
  },
  props: {
    availableOptions: {
      type: Object,
      require: true,
      default() {
        return {
          benefits: [],
          partners: []
        };
      }
    }
  },
  data() {
    return {
      dateFormat: 'dd.MM.yyyy',
      invoiceDetails: {
        id: null,
        import_benefit_invoices_id: null,
        purchase_invoice_number: null,
        code: null,
        voucher_value: null,
        up_purchase_date: null,
        partner_name: null,
        partner_cui: null,
        invoice_value_without_discount: null,
        discount_value: null,
        invoice_value_with_discount: null,
        up_ro_invoice_value_with_discount: null,
        discount_percentage: null,
        vat_percentage: null,
        reconciliation_status: null,
        status: null,
        invalid_reasons: null,
        created_at: null,
        updated_at: null,
      },

      order: {},
    };
  },
  methods: {
    closeModal() {
      this.$refs['details_modal'].hide();
    },
    openModal(invoiceDetails) {
      this.initialSet = false;
      this.invoiceDetails = { ...invoiceDetails};
      this.$refs['details_modal'].show();
    },
    checkInErrorBag(key) {
      return (this.invoiceDetails.invalid_reasons != null && typeof this.invoiceDetails.invalid_reasons[key] != 'undefined')
    },
    confirmAction() {
      this.$emit('confirmAction', this.invoiceDetails);

      this.$refs['details_modal'].hide();
    },
    getInvalidReasons(invalidReasons) {
      return this.$parent.getInvalidReasons(invalidReasons)
    },
    deleteAction() {
      this.$emit('deleteAction', this.invoiceDetails.id);
      this.$refs['details_modal'].hide();
    },
  },
};
</script>
